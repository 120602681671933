import React, {Component} from 'react'
import q from '../../questions.json'

class Board extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <table>
                    <tr>
                        <th>
                            {q.k1}
                        </th>
                        <th>
                            {q.k2}
                        </th>
                        <th>
                            {q.k3}
                        </th>
                        <th>
                            {q.k4}
                        </th>
                        <th>
                            {q.k5}
                        </th>
                        <th>
                            {q.k6}
                        </th>
                    </tr>
                    <tr>
                        <td onClick={() => {
                            document.cookie = "#11=1";
                            window.location.href = "/q#11";
                        }} id="11"
                            className={`${document.cookie.includes("#11") ? "used" : ""}`}
                        >
                            10
                        </td>
                        <td onClick={() => {
                            document.cookie = "#21=1";
                            window.location.href = "/q#21";
                        }} id="21"
                            className={`${document.cookie.includes("#21") ? "used" : ""}`}
                        >
                            10
                        </td>
                        <td onClick={() => {
                            document.cookie = "#31=1";
                            window.location.href = "/q#31";
                        }} id="31"
                            className={`${document.cookie.includes("#31") ? "used" : ""}`}
                        >
                            10
                        </td>
                        <td onClick={() => {
                            document.cookie = "#41=1";
                            window.location.href = "/q#41";
                        }} id="41"
                            className={`${document.cookie.includes("#41") ? "used" : ""}`}
                        >
                            10
                        </td>
                        <td onClick={() => {
                            document.cookie = "#51=1";
                            window.location.href = "/q#51";
                        }} id="51"
                            className={`${document.cookie.includes("#51") ? "used" : ""}`}
                        >
                            10
                        </td>
                        <td onClick={() => {
                            document.cookie = "#61=1";
                            window.location.href = "/q#61";
                        }} id="61"
                            className={`${document.cookie.includes("#61") ? "used" : ""}`}
                        >
                            10
                        </td>
                    </tr>
                    <tr>
                        <td onClick={() => {
                            document.cookie = "#12=1";
                            window.location.href = "/q#12";
                        }} id="12"
                            className={`${document.cookie.includes("#12") ? "used" : ""}`}
                        >
                            20
                        </td>
                        <td onClick={() => {
                            document.cookie = "#22=1";
                            window.location.href = "/q#22";
                        }} id="22"
                            className={`${document.cookie.includes("#22") ? "used" : ""}`}
                        >
                            20
                        </td>
                        <td onClick={() => {
                            document.cookie = "#32=1";
                            window.location.href = "/q#32";
                        }} id="32"
                            className={`${document.cookie.includes("#32") ? "used" : ""}`}
                        >
                            20
                        </td>
                        <td onClick={() => {
                            document.cookie = "#42=1";
                            window.location.href = "/q#42";
                        }} id="42"
                            className={`${document.cookie.includes("#42") ? "used" : ""}`}
                        >
                            20
                        </td>
                        <td onClick={() => {
                            document.cookie = "#52=1";
                            window.location.href = "/q#52";
                        }} id="52"
                            className={`${document.cookie.includes("#52") ? "used" : ""}`}
                        >
                            20
                        </td>
                        <td onClick={() => {
                            document.cookie = "#62=1";
                            window.location.href = "/q#62";
                        }} id="62"
                            className={`${document.cookie.includes("#62") ? "used" : ""}`}
                        >
                            20
                        </td>
                    </tr>
                    <tr>
                        <td onClick={() => {
                            document.cookie = "#13=1";
                            window.location.href = "/q#13";
                        }} id="13"
                            className={`${document.cookie.includes("#13") ? "used" : ""}`}
                        >
                            30
                        </td>
                        <td onClick={() => {
                            document.cookie = "#23=1";
                            window.location.href = "/q#23";
                        }} id="23"
                            className={`${document.cookie.includes("#23") ? "used" : ""}`}
                        >
                            30
                        </td>
                        <td onClick={() => {
                            document.cookie = "#33=1";
                            window.location.href = "/q#33";
                        }} id="33"
                            className={`${document.cookie.includes("#33") ? "used" : ""}`}
                        >
                            30
                        </td>
                        <td onClick={() => {
                            document.cookie = "#43=1";
                            window.location.href = "/q#43";
                        }} id="43"
                            className={`${document.cookie.includes("#43") ? "used" : ""}`}
                        >
                            30
                        </td>
                        <td onClick={() => {
                            document.cookie = "#53=1";
                            window.location.href = "/q#53";
                        }} id="53"
                            className={`${document.cookie.includes("#53") ? "used" : ""}`}
                        >
                            30
                        </td>
                        <td onClick={() => {
                            document.cookie = "#63=1";
                            window.location.href = "/q#63";
                        }} id="63"
                            className={`${document.cookie.includes("#63") ? "used" : ""}`}
                        >
                            30
                        </td>
                    </tr>
                    <tr>
                        <td onClick={() => {
                            document.cookie = "#14=1";
                            window.location.href = "/q#14";
                        }} id="14"
                            className={`${document.cookie.includes("#14") ? "used" : ""}`}
                        >
                            40
                        </td>
                        <td onClick={() => {
                            document.cookie = "#24=1";
                            window.location.href = "/q#24";
                        }} id="24"
                            className={`${document.cookie.includes("#24") ? "used" : ""}`}
                        >
                            40
                        </td>
                        <td onClick={() => {
                            document.cookie = "#34=1";
                            window.location.href = "/q#34";
                        }} id="34"
                            className={`${document.cookie.includes("#34") ? "used" : ""}`}
                        >
                            40
                        </td>
                        <td onClick={() => {
                            document.cookie = "#44=1";
                            window.location.href = "/q#44";
                        }} id="44"
                            className={`${document.cookie.includes("#44") ? "used" : ""}`}
                        >
                            40
                        </td>
                        <td onClick={() => {
                            document.cookie = "#54=1";
                            window.location.href = "/q#54";
                        }} id="54"
                            className={`${document.cookie.includes("#54") ? "used" : ""}`}
                        >
                            40
                        </td>
                        <td onClick={() => {
                            document.cookie = "#64=1";
                            window.location.href = "/q#64";
                        }} id="64"
                            className={`${document.cookie.includes("#64") ? "used" : ""}`}
                        >
                            40
                        </td>
                    </tr>
                    <tr>
                        <td onClick={() => {
                            document.cookie = "#15=1";
                            window.location.href = "/q#15";
                        }} id="15"
                            className={`${document.cookie.includes("#15") ? "used" : ""}`}
                        >
                            50
                        </td>
                        <td onClick={() => {
                            document.cookie = "#25=1";
                            window.location.href = "/q#25";
                        }} id="25"
                            className={`${document.cookie.includes("#25") ? "used" : ""}`}
                        >
                            50
                        </td>
                        <td onClick={() => {
                            document.cookie = "#35=1";
                            window.location.href = "/q#35";
                        }} id="35"
                            className={`${document.cookie.includes("#35") ? "used" : ""}`}
                        >
                            50
                        </td>
                        <td onClick={() => {
                            document.cookie = "#45=1";
                            window.location.href = "/q#45";
                        }} id="45"
                            className={`${document.cookie.includes("#45") ? "used" : ""}`}
                        >
                            50
                        </td>
                        <td onClick={() => {
                            document.cookie = "#55=1";
                            window.location.href = "/q#55";
                        }} id="55"
                            className={`${document.cookie.includes("#55") ? "used" : ""}`}
                        >
                            50
                        </td>
                        <td onClick={() => {
                            document.cookie = "#65=1";
                            window.location.href = "/q#65";
                        }} id="65"
                            className={`${document.cookie.includes("#65") ? "used" : ""}`}
                        >
                            50
                        </td>
                    </tr>
                </table>
            </div>
        )
    }
}

export default Board