import React, {Component} from 'react'
import q from '../../questions.json'

class Question extends Component {

    render() {
        return (
            <div>
                <div className="question">
                    {q[window.location.hash]}
                </div>
                <div className="buttonDiv">
                    <a className="btn" href={window.location.origin + window.location.hash}>ZURÜCK</a>
                </div>
            </div>
        )
    }
}

export default Question