import './style.css'
import React from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Board from "./components/pages/Board";
import Question from "./components/pages/Question";

function App() {

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/q">
                        <Question />
                    </Route>
                    <Route path="/">
                        <Board />
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}


export default App